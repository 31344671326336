$p: ui;
.main {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  @apply
    bg-gray-095
    flex
    flex-col
    items-center
    justify-center
    p-12
    w-full
  ;

  @screen sm {
    @apply gap-4 p-4;
  }
}

.card {
  max-width: 846px;
  @apply
    gap-4
    items-center
    justify-center
    w-full
  ;
}
